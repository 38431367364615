<template>
    <ContentField v-if="!$store.state.user.pulling_info">
        <div class="row justify-content-center">
            <div class="col-3">
                <form @submit.prevent = "login">
                    <div class="mb-3">
                        <label for="username" class="form-label">username</label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="please input username">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">password</label>
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="please input password">
                    </div>
                    <div class="error-message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary">Primary</button>
                </form>
            </div>
        </div>
    </ContentField>
</template>

<script>
import ContentField from '../../../components/ContentField.vue'
import { useStore } from 'vuex';
import { ref } from 'vue';
import router from '../../../router/index'


export default{
    components:{
        ContentField,
    },
    setup(){
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let error_message = ref('');


        const jwt_token = localStorage.getItem("jwt_token");
        if(jwt_token){
            store.commit("updateToken",jwt_token);
            store.dispatch("getInfo",{
                success(){
                    router.push({name:"home"});
                    store.commit("updatePullingInfo",false);
                },
                error(){
                    store.commit("updatePullingInfo",false);
                }
            })
        }else{
            store.commit("updatePullingInfo",false);
            
        }



        const login = () =>{
            error_message.value = "";
            store.dispatch("login",{
                username:username.value,
                password:password.value,
                success(){
                    store.dispatch("getInfo",{
                        success(){
                            router.push({name:'home'});
                        }
                    })
                },
                error(){
                    error_message.value = "用户名或密码错误"
                }
            })
        }
        return {
            username,
            password,
            error_message,
            login,
            
        }
    }   
}

</script>

<style scope>
button{
    width: 100%;
}
div.error-message{
    color:red;
}
</style>