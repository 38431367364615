<template>
    <ContentField>
        <table class="table table-striped" style="text-align: center;">
            <thead>
                <tr>
                    <th>A玩家</th>
                    <th>B玩家</th>
                    <th>对战结果</th>
                    <th>对战时间</th>
                    <th>操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="record in records" :key="record.record.id">
                    <td>
                        <img :src="record.a_photo" alt="" class="record-user-photo">
                        &nbsp;
                        <span class="record-user-username">{{ record.a_username }}</span>
                    </td>
                    <td>
                        <img :src="record.b_photo" alt="" class="record-user-photo">
                        &nbsp;
                        <span class="record-user-username">{{ record.b_username }}</span>
                    </td>
                    <td>
                        {{ record.result }}
                    </td>
                    <td>
                        {{ record.record.createtime }}
                    </td>
                    <td>
                        <button @click="open_record_content(record.record.id)" type="button" class="btn btn-secondary" >查看录像</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <nav aria-label="...">
        <ul class="pagination" style="float: right;">
            <li class="page-item disabled"  @click="click_page(-2)" >
            <span class="page-link" href="#">上一页</span>
            </li>
            <li @click="click_page(page.number)" :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number">
                <a class="page-link" href="#">{{ page.number }}</a>
            </li>
            <li class="page-item"  @click="click_page(-1)" >
            <a class="page-link" href="#">下一页</a>
            </li>
        </ul>
        </nav>
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'
import $ from 'jquery'
import { ref } from 'vue';
import router from '../../router/index'
import store from '@/store';

export default{
    components:{
        ContentField,
    },
    setup(){
        let currentPage = 1;
        let records = ref([]);
        let total_records = 0;
        let pages = ref([]);

        const click_page = page =>{
            if(page === -2) page = currentPage - 1;
            else if(page === -1) page = currentPage + 1;
            let max_pages = parseInt(Math.ceil(total_records/10));
            if(page >= 1 && page <= max_pages){
                pull_page(page);
            }
        }

        const update_pages = () =>{
            let max_pages = parseInt(Math.ceil(total_records/10));
            let new_pages = [];
            for(let i = currentPage - 2; i <= currentPage + 2; i++){
                if(i >= 1 && i <= max_pages){
                    new_pages.push({
                        number:i,
                        is_active: i === currentPage ? "active" : "",
                    });
                }
            }
            pages.value = new_pages;
        }

        const pull_page = page =>{
            currentPage = page;
            $.ajax({
                url:"http://www.str2ke.com/api/record/getlist/",
                type:"GET",
                data:{
                    page,
                },
                headers:{
                    Authorization:"Bearer "+ localStorage.getItem("jwt_token"),
                },
                success:(resp)=>{
                    records.value = resp.records;
                    total_records = resp.records_count;
                    console.log(total_records);
                    update_pages();
                },
            })
        }
        pull_page(currentPage);

        
        const stringTo2D = map => {
            let g = [];
            for (let i = 0, k = 0; i < 13; i ++ ) {
                let line = [];
                for (let j = 0; j < 14; j ++, k ++ ) {
                    if (map[k] === '0') line.push(0);
                    else line.push(1);
                }
                g.push(line);
            }
            return g;
        }



        const open_record_content = recordId =>{
            console.log(recordId);
            for(const record of records.value){
                if(record.record.id === recordId){
                    store.commit("updateIsRecord",true);
                    store.commit("updateGame",{
                        map: stringTo2D(record.record.map),
                        a_id : record.record.aid,
                        a_sx : record.record.asx,
                        a_sy : record.record.asy,
                        b_id : record.record.bid,
                        b_sx : record.record.bsx,
                        b_sy : record.record.bsy,
                    });
                    store.commit("updateSteps",{
                        a_steps:record.record.asteps,
                        b_steps:record.record.bsteps,
                    })
                    store.commit("updateRecordLoser",record.record.loser);
                    router.push({
                        name:"record_content",
                        params:{
                            recordId
                        }
                    })
                    break;
                }
            }
        }
        return{
            records,
            open_record_content,
            pages,
            update_pages,
            click_page,
        }
    },
}

</script>

<style scope>
img.record-user-photo{
    width: 4vh;
    border-radius: 50%;
}
button.btn-bot{
    width: 200px;
}

</style>